import React, { useState, useEffect } from "react"
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo"
import TitleLogo from "../components/title-logo/title-logo";
import { Trans } from 'gatsby-plugin-react-i18next';
import { useI18next } from 'gatsby-plugin-react-i18next';
import axios from "axios";

import "./category.scss"




export default function Category({ data }) {
    const [post, setPost] = useState(data.restApiApiCategories)
    // const post = data.restApiApiCategories;
    console.log('post', post);
    // const [coverImg, setCoverImg] = useState(post.cover);
    const [categoryProduct, setCategoryProduct] = useState([]);
    const { language, languages, changeLanguage } = useI18next();

    const getProducts = () => {

        axios.get(`https://cmsv2.dact.studio/api/category/${post.endpointId}/products/${language}`).then(
            (response) => {
                setCategoryProduct(response.data);
                console.log('categoryProduct', response.data[0]);
            }
        );
    };
    useEffect(() => {
        getProducts();
    }, []);
    return (
        <Layout>
            {categoryProduct.map((category, index) => {
                return (
                    <Seo title={category.name} />
                )
            })}

            <section className="products-page">
                {categoryProduct.map((category, index) => {
                    return (
                        <TitleLogo siteTitle={category.name} siteComment={<span><a href="/" ><Trans>home</Trans></a> / <a href="/products" ><Trans>products</Trans></a></span>} />
                    )
                })}
                <div className="container-fluid text-center mb-5 mt-5" id="slide-button">
                    <div className="row" id="slide-botton">
                        {console.log('asdsadasd', post)}
                        {post.product.length < 1 && <span className="bg-white mt-6"><Trans>no products were found for the category you selected</Trans></span>}
                        {categoryProduct.map((category, index) => {
                            return (
                                category.product.map((product, i) => {
                                    return (
                                        <div className="col-lg-3 col-md-6 mx-0 px-0">
                                            <Link to={'/product-detail/' + product.slug}>
                                                <div className={(index % 2 == 0) ? 'card card-1' : 'card card-2'}>
                                                    <div className="card-title text-align-right pb-0">
                                                        <h5 >{product.name}</h5>
                                                    </div>
                                                    <img className="mx-auto products-image" src={product.cover + '?1'} alt={product.name} />
                                                    <div className="card-img-overlay">
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    )
                                }
                                )

                            )
                        })}

                    </div>
                </div>
            </section>
        </Layout >
    )
}

export const query = graphql`
query ($language: String!, $slug: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }   
    restApiApiCategories(slug: {eq: $slug}) {
        name
        endpointId
        product {
          name
          slug
          cover
        }
      }
  }
`